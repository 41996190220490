import {Icon} from 'react-icons-kit';
import PropTypes from 'prop-types';

function IconComponent(props) {
  const {icon, size, color} = props;
  return (
    <div style={{color: color}} className="logo">
      <Icon icon={icon} size={size}/>
    </div>
  );
}

export default IconComponent;

IconComponent.propTypes = {
  icon: PropTypes.object.isRequired,
  size: PropTypes.number,
  color: PropTypes.string
}

IconComponent.defaultProps = {
  size: 16,
  color: '#fff'
}