import './styles.css';
import React from 'react';

function Volunteer() {
  const volunteerWork = [
    {
      title: 'Earth Day 2020',
      desc: 'Spent the day cleaning the beach in Venice Beach, CA'
    },
    {
      title: 'NBC News Group Community Council',
      desc: 'Member of the forum for ideas to have a constructive dialogue with NBC News leadership about' +
        ' opportunities for NBC News workplace and culture'
    },
    {
      title: 'Improving Connection, Communication And Morale In A WFH Environment',
      desc: 'Member of the group in charge of developing recommendations to improve employee connection and morale' +
        ' while working from home'
    },
    {
      title: 'Wellness Newsletter',
      desc: 'Participated in the creation of newsletter templates for managers to expand the Wellness focus and' +
        ' benefits to News employees'
    },
    {
      title: 'Well/Life balance Event Series',
      desc: 'Pioneered and involved in the creation of the Well/Life balance event series - an event created by NBC' +
        ' News employees for employee events with the first successful event powered by The Today Show host Al' +
        ' Rocker, with on Air talent and News Group Executives'
    },
    {
      title: 'Second Well/Life Balance Event',
      desc: 'Member of the steering committee for the Second Well/Life Balance event powered by CNBC host by Sharon' +
        ' Epperson'
    },
  ];
  return (
    <div className="volunteer dark" id="volunteer">
      <h2>Volunteer Work</h2>
      <section>
        {
          volunteerWork.map(({title, desc}, index) => (
            <div className="activity" key={index}>
              <h4>{title}</h4>
              <li>{desc}</li>
            </div>
          ))
        }
      </section>
    </div>
  );
}

export default Volunteer;