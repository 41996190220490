import './styles.css';
import React from 'react';

function Education() {
  return (
    <div className="education" id="education">
      <h2>Education</h2>
      <section>
        <h5>May 2015</h5>
        <h3>University of North Carolina Charlotte</h3>
        <h3>B.S.B.S MARKETING</h3>
        <h5>Concentration - Marketing Analytics</h5>
        <h5>Minor - Management Information Systems</h5>
        <h4>GPA: 3.74/4.00; Magna Cum Laude</h4>
      </section>
    </div>
  );
}

export default Education;