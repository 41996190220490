import './styles.css';
import React from 'react';
import teletrax from '../../assets/images/teletrax.png';
import iqmedia from '../../assets/images/iqmedia.png';
import googleAnalytics from '../../assets/images/googleAnalytics.png';
import googleCharts from '../../assets/images/googleCharts.png';
import adobeAnalytics from '../../assets/images/adobeAnalytics.png';
import qualtrics from '../../assets/images/qualtrics.png';
import sql from '../../assets/images/sql.png';
import pandas from '../../assets/images/pandas.png';
import excel from '../../assets/images/excel.png';
import powerpoint from '../../assets/images/powerpoint.png';
import word from '../../assets/images/word.png';
import office365 from '../../assets/images/office365.png';
import operativeOne from '../../assets/images/operativeOne.png';
import freewheel from '../../assets/images/freewheel.png';
import hubble from '../../assets/images/hubble.png';
import research from '../../assets/images/research.png';

function Skills() {
  const technicalSkills = [
    {
      img: teletrax,
      text: 'Teletrax'
    },
    {
      img: iqmedia,
      text: 'IQ Media'
    },
    {
      img: sql,
      text: 'SQL'
    },
    {
      img: pandas,
      text: 'Pandas'
    },
    {
      img: googleAnalytics,
      text: 'Google Analytics'
    },
    {
      img: googleCharts,
      text: 'Google Charts'
    },
    {
      img: adobeAnalytics,
      text: 'Adobe Analytics'
    },
    {
      img: qualtrics,
      text: 'Qualtrics'
    },
    {
      img: freewheel,
      text: 'FreeWheel'
    },
    {
      img: operativeOne,
      text: 'Operative One'
    },
    {
      img: hubble,
      text: 'Hubble'
    },
    {
      img: excel,
      text: 'Excel'
    },
    {
      img: powerpoint,
      text: 'Power Point'
    },
    {
      img: word,
      text: 'Microsoft Word'
    },
    {
      img: office365,
      text: 'Office 365'
    },
    {
      img: research,
      text: 'Research'
    },
  ];
  const softSkills = ['Creativity', 'Desire to learn', 'Flexibility', 'Initiative', 'Positive Mindset', 'Self-motivated', 'Optimist'];
  
  return (
    <div className="skills dark" id="skills">
      <h2>SKILLS</h2>
      <section>
        <div className="title">
          <h3>Technical Skills</h3>
        </div>
        <div className="items">
          {
            technicalSkills.map(({img, text}, index) => (
              <div className="item" key={index}>
                <img src={img} alt={text}/>
                <p>{text}</p>
              </div>
            ))
          }
        </div>
      </section>
      <section>
        <div className="title">
          <h3>Soft Skills</h3>
        </div>
        <div className="items">
          <div className="highlight">
            {
              softSkills.map((skill, index) => (
                <span key={index}>{skill}</span>
              ))
            }
          </div>
        </div>
      </section>
    </div>
  );
}

export default Skills;