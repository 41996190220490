import './styles.css';
import React from 'react';

function About() {
  return (
    <div className="about dark" id="about">
      <h2>ABOUT</h2>
      <p>
        Finding the most efficient ways of solving problems with technology is my creative specialty. Raw data is full of 
        insights into the people and systems that utilize it. If our technology can do all the hardest and most tedious work 
        for us, we can be free to innovate and create even more wonderful things. When I’m not having an adventure in 
        data analysis, I am finding adventure on long hikes or in researching web3 and blockchain technology.
      </p>
      <div className="highlight">
        <span>Pandas</span>
        <span>Excel</span>
        <span>Analytical</span>
        <span>Teamwork</span>
        <span>Communication</span>
      </div>
    </div>
  );
}

export default About;