import './styles.css';
import React from 'react';

function Work() {
  return (
    <div className="work" id="work">
      <h2>WORK</h2>
      <section>
        <div className="title">
          <h3>Digital Analyst</h3>
          <h4>NBCUniversal – Ad Sales</h4>
          <h5>August 2021 – Present</h5>
        </div>
        <div className="items">
          <ul>
            <li>Collaborate with National Sales Planners with on-request inventory avails and final sign off on media plans</li>
            <li>Forecasting and allocating Video and Display advertising inventory for The Fandango Network</li>
            <li>Review and approve insertion orders and modifications through order management system and effectively 
              communicate any discrepancies with the Planning team</li>
            <li>Collaborate in the launch of 2022 “Flock together” and “Lunch and Learn” for the Ad Sales department</li>
          </ul>
        </div>
      </section>
      <section>
        <div className="title">
          <h3>Client Analyst</h3>
          <h4>NBC News Channel</h4>
          <h5>March 2015 – August 2021</h5>
        </div>
        <div className="items">
          <ul>
            <li> Query, validate and transform large amounts of data into concise reports to provide support and
              actionable insights for Top Executives’ strategic analytic-driven initiatives
            </li>
            <li>Combine research and data tools to translate business requests into reporting solutions</li>
            <li>Create creative and dynamic methods to conduct efficient research</li>
            <li>Create and maintain baselines to track changes in the use of content</li>
            <li>Support the President, Executive Producer and NBC News leadership in the creation of reports and
              analysis by communicating daily insights
            </li>
            <li>Daily tracking and categorizing of local, national and international news</li>
            <li>Create and update the Database of the USA Team for the 2016 Summer Olympics and 2018 Winter
              Olympics
            </li>
            <li>Tracking and summarizing the news usage of special NBC network events such as The Voice, primetime
              NBC TV Shows, the Emmys Awards, SNL, AGT, Red Nose Day, Clear the Shelters, Superbowl
            </li>
            <li>Tracking of major news events such as the 2016 and 2020 Presidential election, Covid-19 Pandemic,
              2016, 2018 Olympics, Hurricane season, California wildfires among others
            </li>
            <li>Work with third-party vendors and technical representatives to resolve data discrepancies or platform
              issues
            </li>
            <li>Spearheading the first Well/Life Balance event, an event created to pause and prioritize wellness for
              NBCUniversal News Group employees.
            </li>
          </ul>
        </div>
      </section>
      <section>
        <div className="title">
          <h3>Digital Content Specialist Trainee</h3>
          <h4>Norsan Media</h4>
          <h5>October 2014 – March 2015</h5>
        </div>
        <div className="items">
          <ul>
            <li>Update news content on website</li>
            <li>Lowered the website’s bounce rate from 87% to 27%</li>
            <li>Upload content at peak hours for website traffic according to Google Analytics</li>
            <li>Trained employees on using WordPress</li>
            <li>Create Excel spreadsheets with marketing analytics data</li>
            <li>Utilize Social Networking Sites to attract visitors to the webpage</li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default Work;