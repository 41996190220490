import './App.css';
import Navigation from './components/Navigation';
import Home from './components/Home';
import About from './components/About';
import Work from './components/Work';
import Skills from './components/Skills';
import Education from './components/Education';
import Volunteer from './components/Volunteer';
import Credits from './components/Credits';

function App() {
  return (
    <div className="container">
      <Navigation/>
      <Home/>
      <About/>
      <Work/>
      <Skills/>
      <Education/>
      <Volunteer/>
      <Credits/>
    </div>
  );
}

export default App;
