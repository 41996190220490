import './styles.css';
import React from 'react';

function Credits() {
  return (
    <div className="credits" id="credits">
      <p>Photo by <a
        href="https://unsplash.com/@gradienta?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank" rel="noopener noreferrer">Gradienta</a> on <a
        href="https://unsplash.com/s/photos/purple?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank" rel="noopener noreferrer">Unsplash</a></p>
    </div>
  );
}

export default Credits;