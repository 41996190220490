import './styles.css';
import React, {useState} from 'react';
import {ic_menu} from 'react-icons-kit/md/ic_menu';
import {ic_close} from 'react-icons-kit/md/ic_close';
import Icon from '../Icon';

function Navigation() {
  const [show, setShow] = useState(false);
  const navItems = ['Home', 'About', 'Work', 'Skills', 'Education', 'Volunteer'];
  
  const handleNavigation = () => {
    setShow(prevState => !prevState);
  };
  
  return (
    <div className="nav-container">
      <div className="nav-icon" onClick={() => setShow(prevState => !prevState)}>
        <Icon icon={show ? ic_close : ic_menu} size={28} color='#EEEEEE'/>
      </div>
      <div className={`nav ${show ? 'show' : ''}`}>
        <ul className="navbar-menu">
          {
            navItems.map((navItem, id) => (
              <li
                key={id}
                onClick={handleNavigation}>
                <a href={`#${encodeURIComponent(navItem.toLowerCase())}`}>{navItem}</a>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
  
}

export default Navigation;