import './styles.css';
import Icon from 'react-icons-kit';
import {phone} from 'react-icons-kit/icomoon/phone';
import {ic_email} from 'react-icons-kit/md/ic_email';
import {linkedin} from 'react-icons-kit/icomoon/linkedin';
import {ic_file_download} from 'react-icons-kit/md/ic_file_download';
import resume from '../../assets/files/SaraSmithResume.pdf';

function Home() {
  return (
    <div className="home" id="home">
      <div className="content">
        <h1>Sara Smith</h1>
        <ul>
          <li><a href="tel:704-771-5161"><Icon icon={phone} size={28}/></a></li>
          <li><a href="mailto:smith.sara0727@gmail.com"><Icon icon={ic_email} size={28}/></a></li>
          <li><a href="https://www.linkedin.com/in/saraismith/" target="_blank" rel="noopener noreferrer"><Icon icon={linkedin} size={28}/></a></li>
          <li><a href={resume} download><Icon icon={ic_file_download} size={28}/></a></li>
        </ul>
      </div>
    </div>
  );
}

export default Home;